import { useState, ChangeEvent, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

import TransparentSpinner from '../components/spinner/TransparentSpinner';

import { postVet247Service, vet247Unsubscribe } from '../services/vet247Service';
import { fileValidation } from '../services/fileValidationService';

import { IGlobalStorage } from '../shared/interface/reduxStore.interface';
import { IVet247Appointment } from '../shared/interface/vet247.interface';

import Vet247PackageModal from '../components/Vet247PackageModal';
import { notifyMessage } from '../App';
import { setVet247VideoCallToken } from '../shared/service/storageService';

function Vet247Profile() {
  const token = localStorage.getItem('access_token');
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [unsubsubscribeLoading, setUnsubsubscribeLoading] = useState<boolean>(false);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [imageBlob, setImageBlob] = useState<string | null>(null);
  const [service, setService] = useState<IVet247Appointment | null>(null);
  const navigate = useNavigate();

  const [isOpenVet247ModalShow, setIsOpenVet247ModalShow] = useState<boolean>(false);

  const { data } = useSelector((state: IGlobalStorage) => state.user);
  const [isSubscribe, setIsSubscribe] = useState<boolean>(data?.is_subscriber ?? false);

  const handleFileUplaod = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const fileObj: File = e.target.files[0];
      const isValidate = fileValidation(fileObj);
      if (isValidate) {
        setFile(fileObj);
        setImageBlob(URL.createObjectURL(fileObj));
      }
    }
  };

  const handleJoinCall = async () => {
    try {
      setLoading(() => true);
      const appointmentRes = await postVet247Service();
      setVet247VideoCallToken(appointmentRes?.data?.token ?? null);
      setService(appointmentRes?.data ?? null);
      if (appointmentRes?.data?.id && appointmentRes?.data?.room_id) {
        navigate(`/video-call/${appointmentRes.data.id}/${appointmentRes.data.room_id}`);
      } else {
        notifyMessage.ERROR(appointmentRes?.message ?? 'Cannot join video call now!');
      }
      setLoading(() => false);
    } catch (error: any) {
      notifyMessage.ERROR(error?.response?.data?.message ?? 'Something went wrong!');
      setLoading(() => false);
    }
  };

  const unsubscribe = async () => {
    try {
      setUnsubsubscribeLoading(() => true);
      const pkgRes = await vet247Unsubscribe();
      setUnsubsubscribeLoading(() => false);
      setIsSubscribe(false);
    } catch (error) {
      setUnsubsubscribeLoading(() => false);
    }
  };

  useEffect(() => {
    setIsSubscribe(data?.is_subscriber);
  }, [data?.is_subscriber]);

  return (
    <>
      {loading && <TransparentSpinner />}
      {isOpenVet247ModalShow && <Vet247PackageModal onHide={() => setIsOpenVet247ModalShow(false)} setModalShow={setIsOpenVet247ModalShow} show={isOpenVet247ModalShow} />}
      <div className='container-fluid pet-profile'>
        <form>
          <div className='row w-100 m-0 p-0'>
            <div className='col-12 col-md-8 col-lg-7 py-3'>
              <div style={{ position: 'sticky', top: '14px' }}>
                <img className='pet-img-tag rounded-2 w-100 h-100' src={'/images/vet247.jpg'} />
              </div>
            </div>
            <div className='col-12 col-md-4 col-lg-5 py-3'>
              <div>
              {isSubscribe ? (
                    <div className='d-flex'>
                      <button onClick={() => handleJoinCall()} disabled={btnLoading} type='button' className='btn btn-primary py-3 rounded-3 w-100'>
                        {btnLoading && <span className='spinner-border spinner-border-sm text-white' role='status'></span>} Join Call
                      </button>
                      <button onClick={() => unsubscribe()} disabled={unsubsubscribeLoading} type='button' className='btn btn-outline-primary py-3 mx-2 rounded-3 w-100'>
                        {unsubsubscribeLoading && <span className='spinner-border spinner-border-sm text-white' role='status'></span>} Unsubscribe
                      </button>
                    </div>
                  ) : (
                    <button
                      onClick={() => {
                        if (token != null) {
                          setIsOpenVet247ModalShow(() => true);
                        } else {
                          navigate(`/login`);
                        }

                      }}
                      disabled={btnLoading}
                      type='button'
                      className='btn btn-primary py-3 rounded-3 w-100'>
                      {btnLoading && <span className='spinner-border spinner-border-sm text-white' role='status'></span>} Subscribe
                    </button>
                  )}
                
                <h5 className='mb-3 mt-3'>Pet Doctor 24x7 Service</h5>
                <div className='mb-3'>
                  <label htmlFor='doctorBiography' className='font-hahmlet-medium text-secondary mb-2'>
                    Overview :
                  </label>
                  <p className='fs-sm text-gray mb-0'>
                    NexoPet 24x7 Doctor service is dedicated to providing round the clock veterinary care for your beloved pets. Considering the emergencies can happen at any time and our experienced
                    veterinarians team along with support staffs are always ready to offer you the highest quality of care, day or night through video calling service.
                  </p>
                </div>

                <div className='mb-3'>
                  <label htmlFor='whereyouget' className='font-hahmlet-medium text-secondary mb-2'>
                    Where you get:
                  </label>
                  <p className='fs-sm text-gray mb-0'>
                    <ol className='list-group list-group-numbered'>
                      <li className='mb-2 list-group-item'>
                        <strong>24/7 Availability:</strong> Our service ensures that professional veterinary care is available 24 hours a day, 7 days a week. Whether it’s a midnight emergency or a
                        weekend concern, you can count on us.
                      </li>
                      <li className='mb-2 list-group-item'>
                        <strong>Experienced Veterinarians:</strong> <br />- Our team consists of highly trained and experienced veterinarians who are passionate about animal health. They are supported
                        by skilled veterinary technicians and compassionate support staff.
                      </li>
                      <li className='mb-2 list-group-item'>
                        <strong>Comprehensive Care:</strong> <br />- In emergency situations, time is critical. Our streamlined processes ensure that your pet receives immediate attention and the best
                        possible care guidance without delay.
                      </li>
                      <li className='mb-2 list-group-item'>
                        <strong>Telemedicine Support:</strong> <br /> - For non-critical situations, we offer telemedicine consultations. This allows pet owners to get expert advice and determine if
                        an in-person visit is necessary, providing convenience and peace of mind.
                      </li>
                      <li className='mb-2 list-group-item'>
                        <strong>Follow-up and Support:</strong> <br /> - After the initial treatment advice, we guide you for thorough follow-up care and support to ensure your pet's complete
                        recovery. Our team is always available to answer any questions and provide ongoing assistance.
                      </li>
                    </ol>
                  </p>
                </div>

                <div className='mb-3'>
                  <label className='font-hahmlet-medium text-secondary mb-2'>Contact Us:</label>
                  <ul>
                    <li>- Hotline: +8801898 78 2999</li>
                    <li>
                      - Email: <a href='mailto:info@nexopet.com'>info@nexopet.com</a>
                    </li>
                    <li>
                      - Web :{' '}
                      <a target='_blank' href='https://www.nexopet.com/'>
                        www.nexopet.com
                      </a>
                    </li>
                  </ul>
                </div>
                <p className='fs-sm text-gray mb-4'>Your pet’s health and happiness are our top priority. Trust Pet Doctor 24x7 to be there for you and your pets whenever you need us.</p>
                {/* <div className='mb-3'>
                  <label htmlFor='doctorProfessionalExperience' className='font-hahmlet-medium text-secondary mb-2'>
                    Professional Experience
                  </label>
                  <ul className='fs-sm text-gray'>
                    <li className='d-flex mb-2'>
                      <small className='pe-2'>
                        <i className='fa-solid fa-circle text-warning'></i>
                      </small>
                      <span>
                        Repeated participant and winner of the International Conferences, reports on the topic: “Iatrogenic pathology in urology”, “Foreign bodies of the gastrointestinal tract in
                        small domestic animals”{' '}
                      </span>
                    </li>
                    <li className='d-flex mb-2'>
                      <small className='pe-2'>
                        <i className='fa-solid fa-circle text-warning'></i>
                      </small>
                      <span>From 1998 to 2001, he was the chief physician of the Equus veterinary medicine clinic.</span>
                    </li>
                  </ul>
                </div> */}
                <div>
                  
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default Vet247Profile;
