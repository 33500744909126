import { Link } from 'react-router-dom';

import AddToCartBtnComponent from './AddToCartBtn';

import { IProduct } from '../../shared/interface/product.interface';

function ProductCardComponent({ product }: { product: IProduct }) {
  return (
    <>
      <Link className='text-white display-9 h-100' to={`/products/${product?.id}`}>
        <div className='card border-0 shadow-sm rounded-3'>
          <div className='card-body'>
            <p className='fw-bold line-fix-2' style={{ height: '35px' }}>
              {/* <Link to={`${product?.id}`} className='text-secondary fs-md'> */}
                {product?.supplier_product.title}
              {/* </Link> */}
            </p>
            <div>
              <img
                src={`${product?.supplier_product.images[0]?.image}`}
                className='card-img-top'
                style={{
                  height: '9rem',
                  borderRadius: '10px',
                  objectFit: 'contain',
                }}
                alt='...'
              />
            </div>
            <div className='d-flex justify-content-between pt-3'>
              {/* <p>
                <i className={`fa-heart ${!product?.is_favorite ? 'fa-regular' : 'fa-solid text-primary'} object-shadow fs-5 text-gray`}></i>
              </p> */}
              <p>
                {product?.supplier_product?.average_rating ? <i className='fa-solid fa-star fs-5 mx-2 text-warning-2'></i> : ''}
                {product?.supplier_product?.average_rating ? <span className=' fw-bold text-gray'>{product?.supplier_product?.average_rating}</span> : ''}
              </p>
            </div>
            <div className='card-footer px-0 py-0 bg-white'>
              <div className='d-flex justify-content-between align-items-center pt-3 '>
                <p className='fw-bold text-secondary fs-md mb-0'>৳{product && Math.floor(Number(product?.selling_price))}</p>
                <AddToCartBtnComponent product={product} />
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}

export default ProductCardComponent;
