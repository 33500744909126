import { Routes, Route, Navigate } from 'react-router-dom';

import Home from '../pages/Home';
import Login from '../pages/Login';
import Cart from '../pages/Cart';
import ShopByBrand from '../pages/ShopByBrand';
import Product from '../pages/Product';
import ProductDetail from '../pages/ProductDetail';
import Checkout from '../pages/Checkout';

import PrivateRoutes from '../utils/protectedRoute/authProtect';
import Signup from '../pages/Signup';
import ProductDashboard from '../pages/ProductDasboard';
import SalesDashboard from '../pages/SaleDashboard';
import HomeDashboard from '../pages/HomeDashboard';
import OrderDashboard from '../pages/OrderDasboard';
import Unauthorized from '../pages/dashboardUnauthorized';
import NotFound from '../pages/NotFound';
import AppointmentConfirmComponent from '../pages/AppointmentConfirm';
import PetProfileComponent from '../pages/PetProfile';
import ShopByPet from '../pages/ShopByPet';

import AppointmentComponent from '../pages/Appointments';
import GenericTemplateComponent from '../pages/GenericTemplate';
import OrderListComponent from '../pages/OrderList';
import DoctorProfile from '../pages/doctorProfile';
import DoctorList from '../pages/doctorList';
import VideoCalling from '../pages/VideoCall';
import ContactUsComponent from '../pages/ContactPage';
import UserProfileEditComponent from '../pages/UserProfileEdit';
import DoctorOwnProfile from '../pages/DoctorOwnProfile';
import OrderSuccess from '../pages/OrderSuccess';
import OrderCancel from '../pages/OrderCancel';
import { EGenericPageRouteType } from '../shared/enum/generic.enum';
import BlogDetailsComponent from '../pages/BlogDetails';
import DoctorReviewList from '../pages/DoctorReviewList';
import Vet247Profile from '../pages/Vet247Profile';
import Vet247Appointment from '../pages/Vet247Appointments';
import PrivacyAndPolicyComponent from '../components/PrivacyAndPolicy';

const Router = () => {
  const token = localStorage.getItem('access_token');
  return (
    <>
      <Routes>
        <Route path='/contact' element={<Home />} />
        <Route path='/login' element={token ? <Navigate to='/' replace /> : <Signup />} />
        <Route path='/login/otp' element={token ? <Navigate to='/' replace /> : <Login />} />
        <Route path='*' element={<NotFound />} />
        <Route path='/' element={<Home />} />
        <Route path='/shop/pet' element={<ShopByPet />} />
        <Route path='/shop/brand' element={<ShopByBrand />} />
        <Route path='/products' element={<Product />} />
        <Route path='/products/:id' element={<ProductDetail />} />
        <Route path='/cart' element={<Cart />} />
        <Route path='/blogs/:blogId' element={<BlogDetailsComponent />} />
        <Route path='/vet247' element={<Vet247Profile />} />
        <Route element={<PrivateRoutes />}>
          <Route path='/doctor/profile' element={<DoctorOwnProfile />} />
          {/* <Route path='/vet247' element={<Vet247Profile />} /> */}
          <Route path='/vet247/appointment' element={<Vet247Appointment />} />
          <Route path='/video-call/:roomId' element={<VideoCalling />} />
          <Route path='/video-call/:serviceId/:roomId' element={<VideoCalling />} />
          <Route path='/order-success/:orderId' element={<OrderSuccess />} />
          <Route path='/order-success/' element={<OrderSuccess />} />
          <Route path='/order-failed' element={<OrderCancel />} />
          <Route path='/order-cancelled' element={<OrderCancel />} />
          <Route path='/checkout' element={<Checkout />} />
          <Route path='/add-pet' element={<PetProfileComponent />} />
          <Route path='/pet/:petId' element={<PetProfileComponent />} />
          <Route path='/doctors' element={<DoctorList />} />
          <Route path='/doctors/:doctorId' element={<DoctorProfile />} />
          <Route path='/doctors/:doctorId/reviews' element={<DoctorReviewList />} />
          <Route path='/appointment' element={<AppointmentComponent />} />
          <Route path='/appointment/confirm/:appointmentId' element={<AppointmentConfirmComponent />} />
          <Route path='/dashboard' element={<HomeDashboard />} />
          <Route path='/dashboard/products' element={<ProductDashboard />} />
          <Route path='/dashboard/sales' element={<SalesDashboard />} />
          <Route path='/dashboard/orders' element={<OrderDashboard />} />
          <Route path='/dashboard/unauthorized' element={<Unauthorized />} />
          <Route path='/profile' element={<UserProfileEditComponent />} />
          <Route path='/order-list' element={<OrderListComponent />} />
        </Route>
        <Route path={`/${EGenericPageRouteType.PET_LIFESTYLE}`} element={<GenericTemplateComponent />} />
        <Route path={`/${EGenericPageRouteType.PET_ENTERTAINMENT}`} element={<GenericTemplateComponent />} />
        <Route path={`/${EGenericPageRouteType.PET_GROOMING}`} element={<GenericTemplateComponent />} />
        <Route path={`/${EGenericPageRouteType.PET_GUARD}`} element={<GenericTemplateComponent />} />
        <Route path={`/${EGenericPageRouteType.PET_CARE}`} element={<GenericTemplateComponent />} />
        <Route path={`/${EGenericPageRouteType.PET_FRIENDLY}`} element={<GenericTemplateComponent />} />
        <Route path={`/${EGenericPageRouteType.ABOUT_US}`} element={<GenericTemplateComponent />} />
        <Route path={`/${EGenericPageRouteType.COMMUNITY}`} element={<GenericTemplateComponent />} />
        <Route path={`/${EGenericPageRouteType.MEDICINE}`} element={<GenericTemplateComponent />} />
        <Route path={`/${EGenericPageRouteType.VACCINE}`} element={<GenericTemplateComponent />} />
        <Route path={`/${EGenericPageRouteType.HEALTH_TIPS}`} element={<GenericTemplateComponent />} />
        <Route path={`/${EGenericPageRouteType.OTHER}`} element={<GenericTemplateComponent />} />
        <Route path={`/privacy-and-policy`} element={<PrivacyAndPolicyComponent />} />
        <Route path={`/${EGenericPageRouteType.TERMS_OF_USE}`} element={<GenericTemplateComponent />} />
        <Route path={`/${EGenericPageRouteType.OUR_BUSINESS_INFORMATION}`} element={<GenericTemplateComponent />} />
        <Route path='/contact-us' element={<ContactUsComponent />} />
      </Routes>
    </>
  );
};

export default Router;
