import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import ReactPixel from 'react-facebook-pixel';
import { useEffect } from 'react';
import { ICODResponse } from '../shared/interface/order.interface';
import { FormatDateAndTime } from '../shared/constant/time.constant';

function OrderConfirmation({ orderConfirmResponse }: { orderConfirmResponse: ICODResponse | null }) {

  useEffect(() => {
    if (orderConfirmResponse !== null) {

      const contents = orderConfirmResponse?.items?.map((item) => ({
        title: item.product_title,       // Product title
        price: item.total_price,       // Product price
        quantity: item.quantity, // Product quantity
        category: item.product?.category
      }));

      // Track the purchase event
      ReactPixel.track('Purchase', {
        content_name: 'Order', // Name of the content being purchased
        content_ids: orderConfirmResponse.items.map(item => item.id), // Array of product IDs
        content_type: 'product',
        contents: contents,
        value: orderConfirmResponse.total_price, // Total purchase value
        currency: 'BDT', // Currency code
        payment_method: "COD",
        num_items: orderConfirmResponse.items.length // Number of items in the order
      });
    }
  }, []);

  return (
    <>
      <div className='container pt-3'>
        <div className='bg-light-200 d-flex py-5 align-items-center justify-content-center rounded-3 confirmation-box-height'>
          <div className='w-30 text-center'>
            <img src='/images/order-confirm.png' className='mx-auto' alt='' style={{ width: "-webkit-fill-available" }} />
            <h3 className='text-primary text-secondary font-hahmlet-bold'>Order Confirmed !</h3>
            <small className='text-gray py-1 d-block'>
              <strong>Status</strong> : <span>{orderConfirmResponse?.status}</span>
            </small>
            {orderConfirmResponse?.order_date && (
              <small className='text-gray py-1 d-block'>
                <strong>Date</strong> : <span>{dayjs(orderConfirmResponse.order_date).format(FormatDateAndTime)}</span>
              </small>
            )}
            <small className='text-gray py-1 d-block'>
              <strong>Total Ammount</strong> : <span>{orderConfirmResponse?.total_price}</span>
            </small>
            <Link className='text-white display-9 ' to={'/products'}>
              <button className='btn btn-primary py-2 px-4 lh-2 rounded-3'>Continue Shopping</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderConfirmation;
