import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import '../../scss/HotDeal.scss';
import { BASE_IMG_URL } from '../../store/constants/base.constant';

function NextArrow(props: any) {
  const { onClick } = props;
  return (
    <div className={'carousel-control-next'} onClick={onClick}>
      <button className='carousel-control-next' type='button'>
        <i className='fa-solid fa-chevron-right text-primary'></i>
      </button>
    </div>
  );
}

function PrevArrow(props: any) {
  const { onClick } = props;
  return (
    <div className={'carousel-control-prev'} onClick={onClick}>
      <button className='carousel-control-prev' type='button'>
        <i className='fa-solid fa-chevron-left text-primary'></i>
      </button>
    </div>
  );
}

function HotDealComponent({ banners }: any) {
  const settings = {
    slidesToShow: 1,
    arrows: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    centerMode: true,
    centerPadding: '0',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className='card border-0 bg-light-200 pt-0'>
        <div className='card-body'>
          <h5 className='text-secondary font-hahmlet-medium'>Hot Deals</h5>
          <Slider {...settings}>
            {banners?.map((item: any, index: number) => (
              <div key={index} className='px-1'>
                <div className='card mx-auto w-95 bg-light-200 border-0'>
                  <div className='card-body row justify-content-between align-items-center text-gray w-100 mx-0 p-0'>
                    {/* <div className='col-12 col-md-6 col-lg-7 deal-detail pe-3'>
                      <p className='mb-0'>Surprize for You</p>
                      <h6 className='fs-medium'>{item.name}!</h6>
                      <small className='d-block'>{item?.description}</small>
                    </div> */}
                    <div className='col-12 px-0'>
                      <a href={item.redirect_url}>
                        <img className='w-100' src={`${BASE_IMG_URL}/${item.image}`} alt='' />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
}

export default HotDealComponent;
